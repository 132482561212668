<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item @click="goBack"
                                    class="go-back-font">專案詳情</el-breadcrumb-item>
                <el-breadcrumb-item>圖表列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row class="add-btn">
            <el-col>
                <el-button type="primary"
                           @click="toPage('createChart')">新增圖表<i class="el-icon-lx-add el-icon--right"></i></el-button>
            </el-col>
        </el-row>

        <div class="container">
            <TableTemplate :colums="colums"
                           v-loading="listLoading"
                           :data="tableData"
                           :paginationShow="true"
                           :pageIndex="pageIndex"
                           :pageCount="pageCount"
                           @change-page="changePage">
                <!-- operate -->
                <template v-slot:operate="data">
                    <el-button icon="el-icon-edit"
                               size="mini"
                               @click="toPage('editChart',data.data,'edit')">编辑</el-button>
                    <el-button size="mini"
                               icon="el-icon-delete"
                               class="red"
                               @click="handleDelete(data.data)">删除</el-button>
                </template>
            </TableTemplate>
        </div>
    </div>
</template>

<script>
import TableTemplate from '../../../../../components/TableTemplate.vue'
import { chartList, deleteChart } from '../../../../../api/index'
export default {
    components: {
        TableTemplate,
    },
    data() {
        return {
            userId: '',
            projectId: '',
            colums: [
                {
                    name: '圖表名稱',
                    prop: 'name',
                },
                {
                    name: '圖表網址',
                    prop: 'url',
                },
                {
                    name: '圖表排序',
                    prop: 'sort',
                },
                {
                    name: '操作',
                    type: 'slot',
                    attribute: { slotName: 'operate' },
                },
            ],
            tableData: [],
            pageIndex: 1,
            pageCount: 0,
            listLoading: false,
        }
    },
    mounted() {
        if (typeof this.$route.query.id == 'undefined') {
            this.$router.push({ path: 'commercialList' })
        } else {
            this.userId = this.$route.query.id
            this.projectId = this.$route.query.projectId
            this.getData()
        }
    },
    methods: {
        getData() {
            this.listLoading = true
            chartList({
                project_id: this.projectId,
                page: this.pageIndex,
            })
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.pageCount = res.data.total_page
                    }
                    if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.listLoading = false
                })
        },
        toPage(page, info, edit) {
            if (edit == 'edit') {
                this.$router.push({
                    path: page,
                    query: { id: this.userId, projectId: this.projectId, chartId: info.id },
                })
            } else {
                this.$router.push({ path: page, query: { id: this.userId, projectId: this.projectId } })
            }
        },
        handleDelete(row) {
            this.$confirm('确定要删除吗？', '提示', {
                type: 'warning',
            })
                .then(() => {
                    deleteChart({ id: row.id })
                        .then((res) => {
                            if (res.code == 200) {
                                this.$message.success(res.message)
                                this.getData()
                            }
                        })
                        .catch(() => {
                            this.$message.error('失敗')
                        })
                })
                .catch(() => {})
        },
        changePage(val) {
            this.pageIndex = val
            this.getData()
        },
        goBack() {
            this.$router.push({
                path: 'ProjectDetail',
                query: { id: this.userId, projectId: this.projectId },
            })
        },
    },
}
</script>

<style lang="scss">
.go-back-font {
    .el-breadcrumb__inner {
        font-weight: 700;
        color: #303133;
    }
    .el-breadcrumb__inner:hover {
        color: #409eff;
        cursor: pointer;
    }
}
</style>
<style lang="scss" scoped>
.add-btn {
    padding-bottom: 15px;
}
.red {
    color: #ff0000;
}
</style>